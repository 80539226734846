// This here locales
module.exports = {
  // Under construction
  "under-construction.soon": "SOON",
  "under-construction.text-1": "Get ready for the future!",
  "under-construction.text-2": `Discover our upcoming improvements and news. Do not miss it!`,
  // Pages
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",
  "pages.login-title-1": "LOG IN NOW",
  "pages.login-text-1": "Please use your credentials to log in.",
  "pages.login-text-2": "If you are not yet a member, please,",
  "pages.login-register-here": "sign up here",
  "pages.login-text-3": "BE AN ACTIVE PART OF BURGOS",
  "pages.login-title": "Login",
  "pages.name": "Name",
  "pages.message": "Message",
  "pages.email": "E-mail",
  "pages.password": "Password",
  "pages.register": "Register",
  "pages.login-button": "LOGIN",
  "pages.forgot-password-question": "Forget password?",
  "pages.register-text-1":
    "Complete the required information to join comunidadpebur.com and become part of our community",
  "pages.if-you-already-have-an-account": "If you already have an account",
  "pages.log-in-here": "log in here",
  "pages.fullName": "Full Name",
  "pages.fullName-under-text": "Public name that will appear on your posts",
  "pages.fullName-under-text-2":
    "In order to ensure anonymity, it can be any name.",
  "pages.password-confirm": "Confirm password",
  "pages.forgot-title": "RECOVER PASSWORD",
  "pages.forgot-text-1": "Please use your email to reset your password.",
  "pages.forgot-text-2": "If you are not a member, please, ",
  "pages.forgot-register": "sign up",
  "pages.forgot-password": "Forgot Password",
  "pages.change-password": "Change Password",
  "pages.update-your-password": "Update your password",
  "pages.update-your-password-text-1":
    "Fill in the required information to join comunidadpebur.com and become part of our community.",
  "pages.reset": "Reset",
  "pages.burgos-take-park": "Burgos Take Park",
  "pages.forging-the-future": "Forging the Future",
  "pages.rural-burgos": "Rural Burgos",
  "pages.home-text-1":
    "A Digital Space for Innovative Collaboration of the Burgos Rural 2025 Strategic Plan: Connecting Agents, Promoting Innovation and Forging a Sustainable Future for Our Province",
  "pages.begin": "Begin",
  "pages.participate-actively": "Participate actively",
  "pages.participate-actively-text-1":
    "Take the First Step: Register and Contribute to the Future of Burgos by Participating Actively",
  "pages.start-your-journey": "START YOUR JOURNEY",
  "pages.home.register": "SIGN UP",
  "pages.home.contribute-and-collaborate": "CONTRIBUTE AND COLLABORATE",
  "pages.home.register-text-1":
    "Registering on comunidadpebur.com allows you to easily participate. As a resident of Burgos, you can get involved in discussions and projects.",
  "pages.get-informed": "GET INFORMED",
  "pages.explore-and-discover": "EXPLORE AND DISCOVER",
  "pages.get-informed-text-1":
    "Dive into comunidadpebur.com to explore collaborative processes, discover initiatives and filter information according to your interests.",
  "pages.contribute-and-collaborate": "CONTRIBUTE AND COLLABORATE",
  "pages.participates": "PARTICIPATES",
  "pages.actively-contributes": "Actively contributes",
  "pages.actively-contributes-text-1":
    "Share your ideas. Participate in discussions driven by SODEBUR and partners. Express yourself, share your views and contribute to the community.",
  "pages.stay-informed": "STAY INFORMED",
  "pages.keep-track": "KEEP TRACK",
  "pages.keep-track-text-1":
    "Follow the progress of each initiative from start to completion, and stay informed about meetings, discussions and the evolution of ideas.",
  "pages.discover-pebur-community": "Discover PEBUR Community",
  "pages.discover-pebur-community-text-1":
    "Watch our presentation video and learn how we can help you grow",
  "pages.join-the-community": "Join the Community",
  "pages.join-the-community-text-1":
    "Join our online community and actively participate in building the future of Burgos!",
  "pages.check-in": "CHECK IN",
  "pages.participate-without-borders": "Participate without Borders",
  "pages.participate-without-borders-text-1":
    "Your Voice Matters: Contribute to Diversity and Inclusion in Our Virtual Community",
  "pages.we-promote-development": "WE PROMOTE DEVELOPMENT",
  "pages.we-promote-development-text-1":
    "Pebur Community, part of the Burgos Rural Strategic Plan (PEBUR2025) and supported by SODEBUR, is an innovative space that promotes collaboration between various actors to boost development and innovation in the province.",
  "pages.connection-center": "CONNECTION CENTER",
  "pages.connection-center-text-1":
    "The Pebur Community promotes dialogue and cooperation between PEBUR2025 stakeholders, facilitating the exchange of ideas and projects for rural development in Burgos. It acts as a catalyst for effective collaboration on initiatives that shape the future of the province.",
  "pages.commitment-to-innovation": "COMMITMENT TO INNOVATION",
  "pages.commitment-to-innovation-text-1":
    "The Pebur Community promotes a culture of collaboration beyond mere participation. BurgosParticipa.com is proof of this commitment, providing a dynamic space for effective collaboration and the creation of opportunities between the agents of development of PEBUR2025.",
  "pages.active-collaboration": "ACTIVE COLLABORATION",
  "pages.active-collaboration-text-1":
    "Join the Pebur Community and actively participate in this exciting collaborative initiative! Together, we can build a brighter future for our province, based on innovation, shared knowledge and strategic cooperation.",
  "pages.open-to-all": "OPEN TO ALL",
  "pages.open-to-all-text-1":
    "We invite you to actively participate, regardless of your location. We value diversity of opinions and inclusion in our community platform.",
  "pages.your-contribution-our-value": "YOUR CONTRIBUTION, OUR VALUE",
  "pages.your-contribution-our-value-text-1":
    "Participate in digital and in-person debates, enriching the dialogue with your ideas. Share your proposals and comments on existing initiatives.",
  "pages.your-voice-matters": "YOUR VOICE MATTERS",
  "pages.your-voice-matters-text-1":
    "Your voice matters, wherever you are. Your perspectives and experiences enrich our community, driving a more inclusive and representative province of Burgos.",
  "pages.join-and-participate": "JOIN AND PARTICIPATE",
  "pages.join-and-participate-text-1":
    "Join comunidadpebur.com and contribute to the development of our province. Your participation is key to improving the rural environment of Burgos.",
  "pages.face-to-face-meetings": "Face-to-face meetings",
  "pages.face-to-face-meetings-text-1":
    "Real Connections, Local Impact The face-to-face meetings at comunidadpebur.com are vital opportunities for collaborators to meet and work together on projects and initiatives. These meetings are spaces where your contribution and ideas are combined with those of others, creating practical and effective solutions for our provinces.",
  "pages.online-debates": "Online debates",
  "pages.online-debates-text-1":
    "Spaces for Dialogue and Reflection Our platform offers you a digital space to debate and reflect on the various initiatives and projects. Here, you can share your opinions, learn about different perspectives and contribute to the collective development of ideas. Online debates are essential to maintain active and effective communication between participants.",
  "pages.proposals": "Proposals",
  "pages.proposals-text-1": `Driving Ideas into Action The proposals on comunidadpebur.com are the core of innovation and change. They are your ideas transformed into action plans, waiting for collaboration and support from the community to become tangible realities. Each proposal you support contributes to the evolution and improvement of our province

    <h2 style='margin-top: 40px;'>Comments</h2>

    Reflections that Enrich
    Your comments on comunidadpebur.com are essential to enrich the discussions and add value to the proposals and debates. Each opinion, suggestion or criticism is a key piece in the mosaic of our collaboration, helping to maintain a constructive dialogue oriented towards the improvement of the rural environment of the province of Burgos.`,
  "pages.active-participation": "Active participation",
  "pages.active-participation-text-1": `<p>Your Role in Our Community <br>
    At comunidadpebur.com, every element of collaboration is an invitation to actively participate in the development and improvement of our province. Your participation, whether in face-to-face meetings, online debates, proposing ideas or through comments, is crucial to building a stronger and more united community. We invite you to be an active part of this exciting collaborative process!
    </p>`,
  "pages.help": "Help",
  "pages.sign-out": "Sign Out",
  "pages.home": "Home",
  "pages.my-account": "My account",
  "pages.repopulation-and-quality-of-life": "Repopulation and quality of life",
  "pages.business-development": "Business development",
  "pages.green-transition": "Green transition",
  "pages.tourism-development": "Tourism development",
  "pages.smart-territory": "Smart territory",
  "pages.profile": "Profile",
  "pages.information": "Information",
  "pages.preferences": "Preferences",
  "pages.notifications": "Notifications",
  "pages.user-admin": "User administrator",
  "pages.faq": "FAQ's",
  "pages.collaborative-process": "Collaborative process",
  "pages.publications": "Publications",
  "pages.files": "Files",
  "pages.energy-and-environment": "Energy and environment",
  "pages.bio-economy": "Bio Economy",
  "pages.smart-territory-text-1":
    "Be part of the change towards a smart territory and collaborative governance",
  "pages.smart-territory-text-2":
    "We promote smart rural development and inclusive governance in Burgos, integrating all actors in the digital transition of public administrations and economic and social activities.",
  "pages.smart-territory-text-3":
    "Register at comunidadpebur.com and contribute to the digital transformation of rural areas in Burgos.",
  "pages.sign-up-to-get-started": "Sign up to get started",
  "pages.participate-now": "Participate now",
  "pages.join-comunidadpebur-com": "JOIN TO COMUNIDADPEBUR.COM",
  "pages.benefits": "Benefits",
  "pages.benefits-text-1": `• New Sectors: Circular economy, silver economy,
    sustainable tourism. <br />
    • Entrepreneurship: Support for micro and small businesses
    to improve their competitiveness. <br />
    • Talent: Attracting, capturing and retaining talent
    in rural areas.`,
  "pages.benefits-text-2":
    "Join ComunidadPebur.com and discover business and employment opportunities.",
  "pages.discover-the-charm-of-rural-burgos":
    "Discover the charm of rural Burgos",
  "pages.discover-the-charm-of-rural-burgos-text-1":
    "Join comunidadpebur.com and participate in sustainable tourism development.",
  "pages.revaluation-of-your": "Revaluation of your",
  "pages.diverse-heritage": "Diverse Heritage",
  "pages.diverse-heritage-text-1": `We promote the consolidation of the rural environment of Burgos as a sustainable tourist destination,
    revaluing its diverse heritage resources: monuments, nature, intangible heritage,
    ethnographic and gastronomic.`,
  "pages.touristic-development-text-1":
    "It is part of the drive towards sustainable tourism and heritage revaluation",
  "pages.touristic-development-text-2":
    "Register at ComunidadPebur.com and contribute to the development of tourism in rural Burgos.",
  "pages.green-transition-text-1": `• Sustainable Energy: Promotion of renewable energy sources.<br/>
    • Environment: Protection and conservation of the natural environment.<br/>
    • Bioeconomy: Development of economic activities based on biological resources.`,
  "pages.green-transition-text-2":
    "Promoting a Sustainable Production Model in Burgos",
  "pages.green-transition-text-3":
    "Join ComunidadPebur.com and participate in the green transition.",
  "pages.green-transition-text-4": "Empowering a Model",
  "pages.green-transition-text-5": "Sustainable Production in Burgos",
  "pages.green-transition-text-6": `The green transition boosts the potential of rural areas to implement a production model that is respectful of the natural environment, climate-neutral and circular. We responsibly use the endogenous resources of the province of Burgos.`,
  "pages.green-transition-text-7":
    "Be part of the change towards a sustainable future in your community",
  "pages.green-transition-text-8":
    "Register at ComunidadPebur.com and contribute to the green transition in rural areas.",
  "pages.boost-your-business-future-in-rural-areas":
    "Boost your business future in rural areas",
  "pages.economic-boost-and-employment": "Economic Boost and Employment",
  "pages.innovative-in-the-rural-environment":
    "Innovative in the Rural Environment",
  "pages.innovative-in-the-rural-environment-text-1":
    "Business development and job creation are essential for the survival of rural areas as a place of opportunity. We promote new economic sectors (circular economy, silver economy, sustainable tourism) to create innovative and quality employment.",
  "pages.be-part-of-the-positive-change-in-your-community":
    "Be part of the positive change in your community",
  "pages.be-part-of-the-positive-change-in-your-community-text-1":
    "Register at ComunidadPebur.com and contribute to business development and job creation in rural areas.",
  "pages.transform-your-life": "Transform your life",
  "pages.in-the-rural-environment": "in the Rural Environment",
  "pages.in-the-rural-environment-text-1":
    "Únete a ComunidadPebur.com y descubre las oportunidades en tu territorio.",
  "pages.keys-to-rural-repopulation": "Keys to Rural Repopulation",
  "pages.keys-to-rural-repopulation-text-1": `Rural repopulation is key to promoting the territory as a space where people
    can fully develop their life project. Improving the quality of life involves reducing inequalities in basic services such as education, health, transport and connectivity.`,
  "pages.register-at-comunidad-pebur-com-and-help-improve-life-in-rural-areas":
    "Register at ComunidadPebur.com and help improve life in rural areas.",
  "pages.rural-repopulation-text-1": `• Basic Services: Improved education, health, transportation and
    connectivity. <br />
    • Opportunities: Facilities for entrepreneurship and living
    in a healthy environment. <br />`,
  "pages.need-help": "Need help?",
  "pages.need-help-text-1": `<strong>Feeling a bit disoriented on comunidadpebur.com?</strong><br />
    Don't worry, we're here to guide you. If you have questions about any aspect of the platform, from the participatory processes to the details of your account, we're ready to assist you. If you have additional questions or need assistance with any aspect of your participation on comunidadpebur.com, don't hesitate to contact us: For general inquiries, write to us at info@comunidadpebur.com.
    For technical support or problems during participation, contact us at info@comunidadpebur.com with the subject “Support”.
    Remember, your participation is vital to us`,
  "pages.write-to-us-to-resolve-your-doubts":
    "Write to us to resolve your doubts",
  "pages.master-the-platform-with-confidence":
    "Master the Platform with Confidence!",
  "pages.find-answers-to-all-your-questions":
    "Find Answers to All Your Questions",
  "pages.text-1": `Download the ultimate guide to using comunidadpebur.com like a true expert! This PDF contains all the answers to your frequently asked questions,
    helping you get the most out of our platform. If you still have questions after reviewing it, don't hesitate to ask for help by <a href='/help'>clicking here!</a>`,
  "pages.title-1": "ONLINE MODERATOR GUIDE",
  "pages.title-2": "PEBUR 2025 EXECUTIVE SUMMARY",
  "pages.title-3": "FAQS IN PDF",
  "pages.see": "See",
  "pages.looking-for-other-help": "Looking for other help?",
  "pages.title-4": `<strong>Are you a little lost on communitypebur.com?</strong> <br />
    Don't worry, we're here to help. If you have questions about
    any aspect of the platform, from the participation processes
    to the details of your account, we're ready to assist you.`,
  "pages.contact": "Contact",
  "pages.user-type": "User type",
  "pages.user-data": "User data",
  "pages.full-name": "Full name",
  "pages.choose-the-desired-role": "Choose the desired role",
  "pages.full-name-under-text": "Public name that will appear on your posts",
  "pages.full-name-under-text-2":
    "In order to ensure anonymity, it can be any name.",
  "pages.request-change": "Request change",
  "pages.save": "Save",
  "pages.role-change-request-text-3":
    "To see the permissions of the different types of users click",
  "pages.role-change-request-text-4": "here.",
  "pages.select-your-ecosystem-s": "Select your Ecosystems",
  "pages.ecosystems": "Ecosystems",

  // Do not change this translations
  "pages.repoblacion-y-calidad": "Repopulation and quality of life",
  "pages.desarrollo-empresarial": "Business and employment development",
  "pages.transision-verde-sostenible":
    "Green transition: energy and environment",
  "pages.transision-verde-bioecomia": "Green transition: bioeconomy",
  "pages.repoblacion-y-calidad-de-vida": "Repopulation and Quality of Life",
  "pages.desarrollo-turisitco": "Tourism and heritage development",
  "pages.territorio-inteligente": "Smart territory and governance",
  "pages.desarrollo-empresarial-y-empleo":
    "Business and Employment Development",
  "pages.transicion-verde": "Green Transition",
  "pages.desarrollo-turistico": "Tourism Development",

  "pages.users": "Users",
  "pages.faqs": "FAQ's",
  "pages.add-new": "Add New",
  "pages.orderby": "Order By : ",
  "pages.total": "Total: ",
  "pages.viewing": "viewing : ",
  "pages.1": "1",
  "pages.create": "Create",
  "pages.detail": "Detail",
  "pages.debate": "Debate",
  "pages.return": "Return",
  "pages.follow-up": "Follow up",
  "pages.actions": "Actions",
  "pages.phase-1": "Phase 1",
  "pages.phase-2": "Phase 2",
  "pages.phase-3": "Phase 3",
  "pages.phase-4": "Phase 4",
  "pages.date-begin": "Date begin",
  "pages.date-ending": "Date ending",
  "pages.comments": "Comments",
  "pages.send": "Send",
  "pages.add-comment": "Add comment",
  "pages.action-line": "Action line",
  "pages.delete": "Delete",
  "pages.change-phase": "Change phase",
  "pages.greeting": "Publications Space",
  "pages.subtitle":
    "Explore, Share and Participate: Join a Community of Creators and Share Your Voice",
  "pages.subtitle-1":
    "Diversity of Content: Explore More Details in Each Publication",
  "pages.more-information": "More information",
  "pages.posts": "POSTS",
  "pages.posts-text": `In this section, PEBUR community members can share their input, comments and reflections on the province. From suggestions for improving infrastructure to personal stories about life in the region, this is where you can make your voice heard and contribute to the development and well-being of our province.`,
  "pages.texts-videos": "FOTOS Y VIDEOS",
  "pages.texts-text": `Explore our province through the images and videos captured by its residents. From stunning landscapes to cultural events, this section is a showcase for the beauty and diversity of our region.`,
  "pages.events": "EVENTS",
  "pages.events-text": `Discover the most important events taking place in our province. From festivals and fairs to sports and cultural activities, here you will find information on how to participate and enrich your experience in our community.`,
  "pages.surveys": "SURVEYS",
  "pages.surveys-text": `Your opinion is essential to the development and growth of our province. Take part in our surveys to share your ideas, concerns and visions for the future of our community. Join us on the path to a better province for all!`,
  "pages.approval": "APPROVAL",
  "pages.approval-text": `All posts will be reviewed and approved by a PEBUR administrator. You will receive a notification once your post has been approved.`,
  "pages.post": "Post",
  "pages.video": "Video",
  "pages.photos-and-videos": "Photos and videos",
  "pages.meet": "Meet",
  "pages.vote": "Vote",
  "pages.publish": "Publish",
  "pages.title": `Title`,
  "pages.description": `Description`,
  "pages.meeting-type": `Meeting type`,
  "pages.address": `Address`,
  "pages.main-image": `Main image`,
  "pages.link": `URL`,
  "pages.date": `Date`,
  "pages.time": `Time`,
  "pages.write-a-description": `Write a description`,
  "pages.question": `Question`,
  "pages.options": `Options`,
  "pages.allowMultipleResponses": `Allow multiple responses`,
  "pages.voting": `Voting`,
  "pages.not-published": `Not published`,
  "pages.add-an-option": `Add an option`,
  "pages.read-more": `Read more`,
  "pages.read-less": `Read less`,
  "pages.you-are-seeing-all-the-posts": `You are seeing all the posts`,
  "pages.process-information": `Process information`,
  "pages.meeting-main-image": "Meeting main image",
  "pages.phase-details": "Phase details",
  "pages.process-name": `Process name`,
  "pages.begin-date": `Begin date`,
  "pages.ending-date": `Ending date`,
  "pages.previous": `Previous`,
  "pages.next": `Next`,
  "pages.process-debate": `Process debate`,
  "pages.process-return": `Process return`,
  "pages.process-monitoring": `Process monitoring`,
  "pages.search": `Search`,
  "pages.votes": `Votes`,
  "pages.published": `Published`,
  "pages.development-by-nattuar": `Development by NattuAR`,
  "pages.nattuar": `NattuAR`,
  "pages.development-by": `Development by`,
  "pages.upload-file": `Upload file`,
  "pages.cancel": `Cancel`,
  "pages.submit": `Submit`,
  "pages.display-options": `Display options`,
  "pages.approve": `Approve`,
  "pages.reject": `Reject`,
  "pages.filters": `Filters`,
  "pages.user-guide": "User guide",
  "pages.the-platform": "The platform",
  "pages.the-platform-text-1": `Learn step by step how to register, set up your profile, participate in collaborative processes, manage files, create publications and much more.`,

  "pages.how-to-register": "How to register",
  "pages.how-to-register-text-1": `<p>
    <strong>Access the platform:</strong>
    Enter the platform and click on the "Start" button.
    </p>
    </p>
      <strong>Account registration:</strong>
      If you don't have an account yet, register easily. Click on "Register"
      and complete the requested fields: full name,
      email and password.
    </p>
    </p>
      <strong>Start as a citizen:</strong>
      Initially, your registration will be
      as a citizen, but you can change your role from your
      profile later
    </p>`,
  "pages.profile-settings": "Profile settings",
  "pages.profile-settings-text-1": `<p>
    <strong>Access your profile:</strong>
    Once logged in, go to "My Account" and select "Profile".
    </p>
    <p>
      <strong>Profile photo:</strong>
      Change your profile picture by choosing one you like.
      Review and modify data: Check that your data is correct and, if not, modify it.
    </p>
    <p>
      <strong>Role change:</strong>
      In this section, you will be able to choose the type of user you wish to be.
      You will start as a Citizen, but you can request a promotion to Social or Economic Agent, PEBUR Agent,
      or General Administrator. After submitting the request,
      wait for approval, which will be notified by email.
    </p>
    <p>
      <strong>Questions and doubts:</strong>
      If you have questions about user types, please consult the
      FAQs section.
    </p>
    <p>
      <strong>Ecosystem selection:</strong>
      Choose the ecosystems you want to participate in.
    </p>
    `,
  "pages.collaborative-processes": "Collaborative process",
  "pages.collaborative-processes-text-1": `<p>
    <strong>Access to processes: </strong>
    In the side menu, select an ecosystem and then
    go to "Collaborative Processes".</p>
    <p>
      <strong>Process selection: </strong>
      Choose the process that interests you. Each process has
      four phases: Information, Discussion, Feedback and Follow-up.
    </p>
    <p>
      <strong>Phase details:</strong>
      On the right side, you will be able to see the current phase of the process and
      the start and end dates.
    </p>
    <p>
      <strong>Creating a new process:</strong>
      To create a new collaborative process,
      click "Add New."
      Select an image for the process.
      Detail the first phase, including the name of the process and its course of action.
      Define the start and end dates of the process.
      Provide the necessary information and click "Next."
      Fill in the details for the following phases and click "Publish."
    </p>
    <p>
      <strong>Publication and management:</strong>
      Once published, you will be able to view your process and move through the phases by clicking on "Actions" and then "Change Phase."
      You can also delete the process from that section or by right-clicking on the process list and selecting "Delete."
    </p>`,
  "pages.files-text-1": `<p>
    <strong>File Access:</strong>
      In the side menu, select an ecosystem and
      then go to "Files."
    </p>
    <p>
      <strong>Viewing and downloading: </strong>
      Here you can see all the files uploaded
      to the ecosystem. To view and download, click on "Download".
      Upload new files: To upload your own files, click on
      "Add New". You can drag the files or select them manually.
      Select the desired file (image or document) and click on
      "Submit".
      Wait for the file to upload.
    </p>
    <p>
      <strong>Deleting files: </strong>
      If you uploaded the wrong file, right click on the file and select the "Delete" option.
    </p>`,
  "pages.publications-text-1": `<p>
    <strong>Access to publications:</strong>
      In the side menu, select an ecosystem and then
      go to "Posts".
    </p>
    <p>
      <strong>Participation in publications:</strong>
      Here you can interact with the ecosystem's publications, leaving comments, liking and staying informed.
    </p>
    <p>
      <strong>Creating Posts:</strong>
      You can create your own posts with information to start discussions.
    </p>
    <p>
      <strong>Photos and videos:</strong>
      You can create your own image or video posts
      to start discussions.
    </p>
    <p>
      <strong>Schedule meetings: </strong>
      You can schedule meetings, whether in person or online.
      If it's online, leave the meeting link; if it's in person, provide the address. Don't forget to include the date and time.
      Creating polls: Create polls by asking questions or commenting
      on ideas. Provide fixed answers for people to vote on and allow multiple choices
      if necessary.
  </p>`,
  "pages.done": "Done",
  "pages.change-image": "Change image",
  "pages.has-called-a-meeting-with-the-following-details":
    "has called a meeting with the following details:",
  "pages.alert-delete": "Delete",
  "pages.alert-delete-text-1": "Are you sure you want to delete the post?",
  "pages.alert-delete-text-2": "Are you sure you want to delete the comment?",
  "pages.thanks": "Thanks!",
  "pages.saved": "Saved",
  "pages.like": "Like",
  "pages.likes": "Likes",
  "pages.upload-by": "Upload by",
  "pages.name-of-file": "Name of file",
  "pages.download": "Download",
  "pages.permissions": "Permissions",
  "pages.pebur-community": "Pebur Community",
  "pages.phone-number-1": "947 061929",
  "pages.email-1": "info@comunidadpebur.com",
  "pages.address-1": "Avenue the Place, 34. Mezzanine. 09004 Burgos",
  "pages.executive-summary-2025": "Executive Summary 2025",
  "pages.moderator-s-guide": "Moderator's Guide",
  "pages.all-right-reserved": "All rights reserved",
  "pages.links": "Links",
  "pages.footer.contact": "Contact",
  "pages.funded-by": "Funded by",
  "pages.reject-reason": "Reject reason",
  "pages.write-reason": "Write the reason",
  "pages.change-rol-nothing": "No requests",
  "pages.delete-user-question-text":
    "Are you sure you want to delete this user?",
  "pages.role-change-request-text-1":
    "The administrator of communitypebur.com has received your request and will contact you as soon as possible to give you access.",
  "pages.role-change-request-text-2": "Pending request",
  "pages.role-change-request": "Role change request",
  "pages.status": "Status",
  "pages.author": "Author",
  "pages.type": "Type",
  "pages.password-recovery-request-sent": "Password recovery request sent",
  "pages.password-recovery-request-sent-text-1":
    "Please check your email and follow the instructions to restore access to your account.",
  "pages.updated-user-data": "Updated user data",
  "pages.updated-user-data-text-1":
    "Your personal data has been successfully modified.",
  "pages.your-message-has-been-sent": "Your message has been sent",
  "pages.your-message-has-been-sent-text-1":
    "Thank you for contacting us. We will get back to you as soon as possible.",
  "pages.business-and-employment-development":
    "Business and employment development",
  "pages.error": "Error",
  "pages.drag-your-files-here-to-upload-them":
    "Drag your files here to upload them",
  "pages.phase": "Phase",
  "pages.email-already-registered": "Email Already Registered",
  "pages.email-already-registered-text-1":
    'The email you entered is already associated with a PEBU2025 account. If you have forgotten your password, you can reset it via the "Forgot your password?" option on the login page.',
  "pages.edit": "Edit",
  "pages.attachments": "Attachments",
  "pages.add-new-folder": "Add new folder",

  /* Begin Post types */
  "postTypes.type-2": "Post",
  "postTypes.type-5": "Photos and videos",
  "postTypes.type-4": "Meetings",
  "postTypes.type-3": "Vote",
  /* Ending Post types */

  // Profile
  "profile.citizen": "Citizen",
  "profile.associate-agents": "Agents associations",
  "profile.collaborator-administrator": "Contributing Administrator",
  "profile.mediator-administrator": "Mediating administrator",
  "profile.general-administrator": "General administrator",
  "profile.agent-pebur2025": "Agent PEBUR2025",
  "profile.provincial-social-economic-agent": "Social/economic agent province",
  "profile.provincialSocialEconomicAgent": "Social/economic agent province",
  "profile.agentPebur2025": "Agent PEBUR2025",
  "profile.administradorGeneral": "General administrator",

  // Error
  "error.page-not-exists":
    "Oops! It looks like you've reached a page that doesn't exist.",
  "error.invalid-url": "URL invalid",
  "error.incorrect-username-or-password":
    "Incorrect username or password. Please check your details and try again.",
  "pages.close": "Close",
  "pages.view-profile": "View profile",
  "pages.reason-for-request": "Reason for request",
  "pages.repopulation": "Repopulation",
};
